<template>
  <div class="topBan">
    <h2>关于我们</h2>
    <p>四川赤壁量化科技有限公司，是一家以科技服务金融的高新技术型企业</p>
    <p>
      公司自成立以来，致力于研发更为便捷的智能辅助工具，量化平台，期货大数据，为期货用户提供专业的软件工具服务。
    </p>
  </div>
  <el-divider> 业务简介 </el-divider>
  <div class="text">
    <h5>
      <i><ins>TOB</ins></i>
    </h5>
    <p>
      <span>①</span
      >为期货公司提供专业的辅助工具，便捷下单，AI大数据分析解决方案，云盯盘，云计算等，一站式解决后居间时代，期货公司拓客难问题
    </p>
    <p>
      <span>②</span
      >为机构或私募公司，提供算法交易/建模训练等方案和策略实现，方便机构能更精准，快速的分析当前市场的供需关系，以及舆情解读
    </p>
    <p>
      <span>③</span
      >为产业户（农产品/钢材等生产和贸易商等）提供现货市场供需关系和库存数据分析，提供专业的套期保值/期权套利等一站式便捷公式，更好的保障现货企业的低风险生产运营
    </p>
    <h5 class="t2">
      <i><ins>TOC</ins></i>
    </h5>
    <p><span>①</span>为广大投资者提供专业的期货量化平台</p>
    <p><span>②</span>为广大投资着提供风险管理，云盯盘，策略超市等辅助工具</p>
    <p><span> ③</span>为广大投资着提供0基础python量化培训学习服务</p>
    <h5 class="t2">
      <i><ins>KNP</ins></i>
    </h5>
    <p>
      <span>①</span
      >公司拥有国家高新技术企业资质，中小企业资质，多份知识产权证书。
    </p>
  </div>
  <div class="call">
    <h2>联系我们</h2>
    <p>四川赤壁量化科技有限公司</p>
    <div>
      <el-icon><HomeFilled /></el-icon><span>公司地址：</span>
      <span>四川省成都市武侯区奥克斯广场 C 座</span>
    </div>
    <div>
      <el-icon><Avatar /></el-icon><span>客服电话: </span>
      <span>028-60119342</span>
    </div>
    <div>
      <el-icon><Briefcase /></el-icon><span>商务咨询:</span>
      <span>13880072820</span>
    </div>
    <div>
      <el-icon><PhoneFilled /></el-icon><span>技术支持: </span>
      <span>18512805580</span>
    </div>
    <div>
      <el-icon><Message /></el-icon><span>公司邮箱:</span>
      <span>wuchen@jukequant.com</span>
    </div>
  </div>
</template>



<style scoped>
@media (min-width: 1200px) {
  .topBan {
    width: 100%;
    background-image: url("../image/about.png");
    height: 330px;
    padding-top: 150px;
    color: rgb(255, 255, 255);
    text-align: center;
  }
  .topBan h2 {
    font-size: 42px;
    margin-bottom: 20px;
  }
  .topBan p {
    line-height: 30px;
  }
  .el-divider {
    margin: 55px 0;
  }
  .el-divider >>> .el-divider__text {
    font-size: 32px;
    color: #212529;
    font-weight: bold;
    background-color: #f9f9f9;
  }

  .text {
    width: 1200px;
    margin: 0px auto;
    background: #e0e4e9;
    padding: 50px 20px;
    box-sizing: border-box;
  }
  .text h5 {
    font-size: 22px;
    color: #1782e6;
  }
  .text h5.t2 {
    margin-top: 50px;
  }
  .text p {
    line-height: 30px;
    font-size: 14px;
    color: #6e6e6e;
  }
  .text p span {
    font-size: 16px;
    color: #0d99ff;
    display: inline-block;
    margin-right: 5px;
  }
  .call {
    width: 1200px;
    margin: 50px auto;
    background: #f3f3f3;
    padding: 50px 20px;
    box-sizing: border-box;
  }

  .call h2 {
    font-size: 20px;
    margin-bottom: 20px;
    color: #1e1e1e;
  }
  .call p {
    font-weight: bold;
    color: #173149;
    margin-bottom: 10px;
  }
  .call div {
    font-size: 14px;
    height: 25px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .call div span:nth-child(2) {
    display: inline-block;
    width: 75px;
    color: #5d5d5d;
  }
  .call div span:nth-child(3) {
    color: #696969;
  }

  .call div .el-icon {
    font-size: 16px;
    font-size: 16px;
    color: #656565;
    margin-right: 5px;
  }
}
@media (min-width: 769px) and (max-width: 1200px) {
  .topBan {
    width: 100%;
    background-image: url("../image/about.png");
    background-repeat: no-repeat;
    height: 260px;
    background-size: 100% 330px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 70px 1.5rem 0 1rem;
  }
  .topBan h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  .topBan p:nth-child(3) {
    text-align: justify;
  }
  .topBan p {
    line-height: 1.5rem;
  }
  .el-divider {
    margin: 1.5rem 0;
  }
  .el-divider >>> .el-divider__text {
    font-size: 22px;
    color: #212529;
    font-weight: bold;
    background-color: #f9f9f9;
    white-space: nowrap;
  }

  .text {
    width: 97vw;
    margin: 0px auto;
    background: #e0e4e9;
    padding: 1.5rem 1rem;
    box-sizing: border-box;
  }
  .text h5 {
    font-size: 1.2rem;
    color: #1782e6;
  }
  .text h5.t2 {
    margin-top: 2rem;
  }
  .text p {
    line-height: 1.5rem;
    font-size: 0.9rem;
    color: #6e6e6e;
  }
  .text p span {
    font-size: 1rem;
    color: #0d99ff;
    display: inline-block;
    margin-right: 0.4rem;
  }

  .call {
    width: 97vw;
    margin: 2rem auto;
    background: #f3f3f3;
    padding: 1.5rem 1rem;
    box-sizing: border-box;
  }

  .call h2 {
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
    color: #1e1e1e;
  }
  .call p {
    font-weight: bold;
    color: #173149;
    margin-bottom: 0.5rem;
  }
  .call div {
    font-size: 0.8rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    font-weight: bold;
  }

  .call div span:nth-child(2) {
    display: inline-block;
    width: 4rem;
    color: #5d5d5d;
  }
  .call div span:nth-child(3) {
    color: #696969;
  }

  .call div .el-icon {
    font-size: 1rem;
    color: #656565;
    margin-right: 0.4rem;
  }
}
@media (max-width: 768px) {
  .topBan {
    width: 100%;
    background-image: url("../image/about.png");
    background-repeat: no-repeat;
    height: 200px;
    background-size: 100% 230px;
    color: rgb(255, 255, 255);
    text-align: center;
    padding: 30px 1.5rem 0 1rem;
    box-sizing: border-box;
  }
  .topBan h2 {
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
  }

  .topBan p {
    margin-bottom: 1rem;
    font-size: 0.8rem;
    text-align: justify;
  }
  .el-divider {
    margin: 1rem 0;
  }
  .el-divider >>> .el-divider__text {
    font-size: 0.8rem;
    color: #212529;
    font-weight: bold;
    background-color: #f9f9f9;
    white-space: nowrap;
  }

  .text {
    width: 98vw;
    margin: 0 auto 1rem;
    background: #e0e4e9;
    padding: 1rem 1rem;
    box-sizing: border-box;
  }
  .text h5 {
    font-size: 1rem;
    color: #1782e6;
  }
  .text h5.t2 {
    margin-top: 1rem;
  }
  .text p {
    line-height: 1.2rem;
    font-size: 0.7rem;
    color: #6e6e6e;
  }
  .text p span {
    font-size: 0.9rem;
    color: #0d99ff;
    display: inline-block;
    margin-right: 0.2rem;
  }
  .call {
    width: 98vw;
    margin: 1rem auto;
    background: #f3f3f3;
    padding: 1rem 1rem;
    box-sizing: border-box;
  }

  .call h2 {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
    color: #353535;
  }
  .call p {
    font-size: 0.8rem;
    font-weight: bold;
    color: #173149;
    margin-bottom: 0.4rem;
  }
  .call div {
    font-size: 0.7rem;
    height: 1.2rem;
    display: flex;
    align-items: center;
  }

  .call div span:nth-child(2) {
    display: inline-block;
    width: 4rem;
    color: #5d5d5d;
  }
  .call div span:nth-child(3) {
    color: #696969;
  }

  .call div .el-icon {
    font-size: 1rem;
    color: #656565;
    margin-right: 0.3rem;
  }
}
</style>